import React from 'react';
import './FaqSection.css'

function FaqSection() {
  return (<div className='faqSection'>

  <h1 className='faqSectionTitle'>FAQ ! BESOIN D'AIDE ? </h1>
  <h5 className='faqSectionDescription'> Bienvenue sur la FAQ de la plateforme Centre, Frappe et But !</h5>

  <h6 className='faqSectionDescription2'>Vous avez une question ? Nous avons probalement la réponse</h6>
  <h6 className='faqSectionDescription2'>Explorez ci-dessous les différentes catégories ou contactez-nous ! </h6>

  </div>
  )
}

export default FaqSection